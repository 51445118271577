<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs">
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <ThePaymentDataTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex"

export default {
  components: {
    ThePaymentDataTable: () =>
      import(
        "@/components/dashboardComponents/ThePaymentDataTable"
      )
  },
  data: () => ({
    reload: false,
    create: false,
    page: {
      title: "Lista de pagos"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        to: "/dashboard/index/"
      },
      {
        text: "Lista de pagos",
        disabled: true
      }
    ]
  }),
  methods: {
    ...mapMutations(['SET_SEARCH'])
  }
};
</script>
